"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { trackEvent } from "@/actions/meta";
import { getStripe } from "@/actions/stripe/client";
import { checkoutWithStripe } from "@/actions/stripe/server";
import { useAccess } from "@/context/access-context";
import { Check, ChevronRight } from "lucide-react";
import { toast } from "sonner";

import { cn, formatPrice } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
import { FacebookTrackOnClick } from "@/components/meta";

export const CheckoutButton = ({
  priceId,
  coupon,
  className,
  mode,
  showPrice = false,
  children,
}: {
  priceId?: string;
  coupon?: string;
  mode?: "payment" | "setup" | "subscription";
  className?: string;
  showPrice?: boolean;
  children?: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const [priceInfo, setPriceInfo] = useState<{
    amount?: number;
    currency?: string;
  }>({});
  const [toltReferralId, setToltReferralId] = useState<string | null>(null);

  const { accessState, isLoading } = useAccess();
  const hasAccess = priceId ? accessState[priceId]?.hasAccess : false;

  useEffect(() => {
    if (typeof window !== "undefined" && (window as any).tolt_referral) {
      setToltReferralId((window as any).tolt_referral);
    }
  }, []);

  useEffect(() => {
    if (!priceId) return;
    if (accessState[priceId]) {
      setPriceInfo({
        amount: accessState[priceId].amount!,
        currency: accessState[priceId].currency!,
      });
    }
  }, [accessState, priceId]);

  const handleClick = async () => {
    if (!priceId) return;
    setLoading(true);
    const [data, err] = await checkoutWithStripe({
      priceId,
      coupon,
      mode,
      referralId: toltReferralId || undefined,
    });
    if (err) {
      toast.error(err.message);
      setLoading(false);
      return;
    }
    if (!data?.sessionId) {
      toast.error("Error creating checkout session " + data?.errorRedirect);
      setLoading(false);
      return;
    }

    const stripe = await getStripe();
    stripe?.redirectToCheckout({ sessionId: data?.sessionId });
    setLoading(false);
  };

  const isDisabled = showPrice
    ? loading || !priceInfo?.amount || !priceInfo?.currency
    : loading || isLoading;

  if (hasAccess) {
    return (
      <Link href="/templates" className={cn(className)}>
        You have access
        <Check className="h-4 w-4" />
      </Link>
    );
  }

  return (
    <FacebookTrackOnClick
      event={{
        event_name: "InitiateCheckout",
        custom_data: {
          value: priceInfo.amount,
          currency: priceInfo.currency,
        },
      }}
      action={trackEvent}
    >
      <Button onClick={handleClick} disabled={isDisabled} className={className}>
        {isDisabled ? (
          <>
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            Loading...
          </>
        ) : (
          <>
            {children}
            {showPrice && priceInfo.amount && priceInfo.currency && (
              <>
                {" - "}
                {formatPrice(priceInfo.amount, {
                  currency: priceInfo.currency,
                })}
              </>
            )}
            <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
          </>
        )}
      </Button>
    </FacebookTrackOnClick>
  );
};
